/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';

const TestApp: React.FC = () => {
  useEffect(() => {
    // Load the first script
    const script = document.createElement('script');
    script.src = 'https://philuat.rotary.org/api/loadData.js'; // Path to your JS file
    script.async = true;

    script.onload = () => {
      const reactBuildUrl = 'https://philuat.rotary.org/api/main.js'; // Update with your actual build path
      const initialData = {
        apiUrl: 'https://api.example.com',
        mode: 'giv',
        user: {
          name: 'John Doe',
          id: 12345,
        },
      };

      // Assuming loadReactApp is defined globally by loadData.js
      if (typeof (window as any).loadReactApp === 'function') {
        (window as any).loadReactApp(reactBuildUrl, initialData, 'app');
      }
      // console.log('Script loaded successfully.');
    };

    // Load the second script only after the first one has loaded
    const script1 = document.createElement('script');
    script1.src = 'https://philuat.rotary.org/api/main.js'; // Path to your built React app's main JS file
    script1.async = true;

    // Append scripts to the body
    document.head.appendChild(script);
    document.head.appendChild(script1);

    // Load the CSS file
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://philuat.rotary.org/api/main.css'; // Path to your CSS file
    document.head.appendChild(link);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script1);
      document.head.removeChild(link);
    };
  }, []);

  return <div id="app" />;
};

export default TestApp;
